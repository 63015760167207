<script>
import { VueEcharts } from "vue3-echarts";
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import doughnutChart from "../../../components/widgets/chartComponent/doughnutChart.vue"
// import Profile from "@/components/widgets/profile";
// import Earning from "@/components/widgets/earning";
import Stat from "@/components/widgets/stat";
// import Transaction from "@/components/widgets/transaction";
// import Emailsent from "@/components/widgets/emailsent";
// import SocialSource from "@/components/widgets/social-source";
// import Activity from "@/components/widgets/activity";
// import SellingProduct from "@/components/widgets/selling-product";
import { Erreur } from "../../../helpers/error";
import { Api } from '../../../helpers';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // Profile,
    Stat,
    doughnutChart,
    // Transaction,
    // Earning,
    // Emailsent,
    VueEcharts,
    // SocialSource,
    // Activity,
    // SellingProduct,
  },

  data() {
    return {
      userCount: "",
      likesNombers: "",
      commentNomber: "",
      favNombers: "",
      vuesNombers: "",
      likes: [],
      videos: [],
      comment: [],
      fav: [],
      vues: [],
      title: "Tableau de bord",
      items: [
        {
          text: "Vue d'ensemble",
          // href: "/",
        },
        {
          text: "Vue d'ensemble",
          active: true,
        },
      ],
      lineChart: {
        // Setup grid
        grid: {
          zlevel: 0,
          x: 50,
          x2: 50,
          y: 30,
          y2: 30,
          borderWidth: 0,
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'rgba(0,0,0,0)',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
          axisLine: {
            lineStyle: {
              color: '#8791af'
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#8791af'
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(166, 176, 207, 0.1)"
            }
          }
        },
        legend: {
          data: ['Inscription'/*, 'Vue de Video', 'Tablets'*/],
          textStyle: {
            color: '#999'
          }
        },
        series: [{
          name: 'Inscription',
          type: 'line',
          stack: 'yes',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
          // {
          //     name: 'Laptops',
          //     type: 'line',
          //     stack: 'yes',
          //     data: [220, 182, 191, 234, 290, 330, 310]
          // },
          // {
          //     name: 'Tablets',
          //     type: 'line',
          //     stack: 'yes',
          //     data: [150, 232, 201, 154, 190, 330, 410]
          // },
        ]
      },
      statData: [
        {
          icon: "bx bx-user-circle",
          title: "Utilisateurs",
          value: 74,
        },
        {
          icon: "bx bx-video",
          title: "Videos",
          value: "$35, 723",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Likes",
          value: "$16.2",
        },
        {
          icon: "bx bx-message-rounded-dots",
          title: "Commentaires",
          value: "$16.2",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Favorie",
          value: "$16.2",
        },

        {
          icon: "bx bx-purchase-tag-alt",
          title: "Temp de visionage",
          value: "$16.2",
        },
      ],
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
      ],
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      loader: "spinner",
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      timeout: 3000, //ms
      fetchingStats: true,
      earningStatus: true,
      stats: [],
      statUser: [],
      serieData: [],
    };
  },
  methods: {
    formatNumber(number) {
      if (number >= 1000000000) {
        return (number / 1000000000).toFixed(1).toLocaleString() + ' milliards';
      } else if (number >= 1000000) {
        return (number / 1000000).toFixed(1).toLocaleString() + ' M';
      } else if (number >= 1000) {
        return (number / 1000).toFixed(1).toLocaleString() + ' K';
      } else {
        return number.toLocaleString();
      }
    },
    getStatsData() {

      Api.get('/authentication/api/auth/users-count')
        .then((response) => {
          this.userCount = this.formatNumber(response.data.content)
          console.log(this.userCount)

          Api.get('/authentication/api/auth/stat-user/enable')
            .then((response) => {
              const userEnable = response.data.content
              console.log(userEnable)


              this.statUser.push(
                {
                  id: 1,
                  icon: "mdi mdi-account-circle",
                  title: "Utilisateurs",
                  value: this.userCount
                },
                {
                  id: 2,
                  icon: "mdi mdi-account-check",
                  title: "Utilisateurs Vérifié",
                  value: this.formatNumber(userEnable[1].val)
                },
                {
                  id: 3,
                  icon: "mdi mdi-account-alert",
                  title: "Utilisateurs non vérifié",
                  value: this.formatNumber(userEnable[0].val)
                },
              )
              this.serieData.push(
                {
                  value: userEnable[1].val,
                  name: 'Utilisateurs vérifiés'
                },
                {
                  value: userEnable[0].val,
                  name: 'Utilisateurs non vérifiés'
                }
              )
            }).catch((error) => {
              this.process = false
              console.log(error)
              Erreur.gestionErreur(error.message)
            })

          console.log(this.formatNumber(1500))

        }).catch((error) => {
          this.process = false
          console.log(error)
          Erreur.gestionErreur(error.message)
        })

      Api.get("/streamvod/api/v2/video/all")
        .then((res) => {
          this.videos = res.data.content
          console.log(this.videos)
          // afficher le nombre de commentaires
          this.videos.forEach(items => {
            this.comment.push(items.comments.length)
          })
          this.commentNomber = this.formatNumber(this.comment.reduce((total, currentValue) => total + currentValue, 0));
          console.log(this.commentNomber)


          //Afficher le nombre de likes
          this.videos.forEach(items => {
            this.likes.push(items.likes.length)
          })
          this.likesNombers = this.formatNumber(this.likes.reduce((total, currentValue) => total + currentValue, 0));
          console.log(this.likesNombers)


          //Aficher le nombre de favories
          this.videos.forEach(items => {
            this.fav.push(items.favorites.length)
          })
          this.favNombers = this.formatNumber(this.fav.reduce((total, currentValue) => total + currentValue, 0));
          console.log(this.favNombers)


          //Aficher le nombre de vue
          this.videos.forEach(items => {
            this.vues.push(items.nbViews)
          })
          this.vuesNombers = this.formatNumber(this.vues.reduce((total, currentValue) => total + currentValue, 0));
          console.log(this.vuesNombers)

          const statistique = [
            {
              id: 2,
              icon: "mdi mdi-video-vintage",
              title: "Vidéos",
              value: this.videos.length
            },
            {
              id: 3,
              icon: "mdi mdi-thumb-up",
              title: "Likes",
              value: this.likesNombers
            },
            {
              id: 4,
              icon: "mdi mdi-chat-processing",
              title: "Commentaires",
              value: this.commentNomber
            },
            {
              id: 5,
              icon: "mdi mdi-bookmark",
              title: "Favoris",
              value: this.favNombers
            },
            {
              id: 6,
              icon: "mdi mdi-eye",
              title: "Vues",
              value: this.vuesNombers
            }
          ]

          statistique.forEach(element => {
            this.stats.push(element)
          })


        }).catch((error) => {
          this.process = false
          console.log(error)
          Erreur.gestionErreur(error.message)
        })




    }
  },
  mounted() {
    if (localStorage.getItem('jwtToken') == null) {
      this.$router.push(this.$route.query.redirectFrom || { name: "login" });
    } else {
      this.getStatsData();

    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!-- <div class="col-xl-4">
        <Profile :updating="fetchingStats" />
        <Earning :updating="earningStatus" />
      </div> -->
      <!-- end col -->
      <div class="col-xl-12">
        <div>
          <h3>Utilisateurs</h3>
          <div class="row">
            <div class="col-lg-5">
              <Stat v-for="stat in statUser" :key="stat.id" :icon="stat.icon" :title="stat.title" :value="stat.value" />
            </div>
            <div class="col-lg-7">
              <doughnutChart :serieData="this.serieData" v-if="this.serieData.length > 0"></doughnutChart>
            </div>
          </div>
        </div>

        <div>
          <h3>Vidéos</h3>
          <div class="row">
            <Stat v-for="stat in stats" :key="stat.id" :icon="stat.icon" :title="stat.title" :value="stat.value"
              :className="'col-lg-4'" />
          </div>
        </div>


        <!-- <Emailsent :updating="fetchingStats" /> -->
      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{ $t("pages.dashboard.graph") }}</h4>
          <vue-echarts :option="lineChart" style="height: 300px" ref="chart" />
        </div>
      </div>
    </div>
    <!-- end row -->

    <!-- <div class="row">
      <div class="col-xl-4">
        <SocialSource :updating="fetchingStats" />
      </div>
      <div class="col-xl-4">
        <Activity :updating="fetchingStats" />
      </div>
      <div class="col-xl-4">
        <SellingProduct :updating="fetchingStats" />
      </div>
    </div> -->
    <!-- end row -->

    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Latest Transaction</h4>
            <Transaction
              :transactions="transactions"
              :updating="fetchingStats"
            />
          </div>
        </div>
      </div>
    </div> -->
    <!-- end row -->

    <b-modal v-model="showModal" hide-footer centered header-class="border-0">
      <div class="text-center mb-4">
        <div class="avatar-md mx-auto mb-4">
          <div class="avatar-title bg-light rounded-circle text-primary h1">
            <i class="mdi mdi-email-open"></i>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-xl-10">
            <h4 class="text-primary">Subscribe !</h4>
            <p class="text-muted font-size-14 mb-4">
              Subscribe our newletter and get notification to stay update.
            </p>

            <div class="input-group bg-light rounded">
              <input type="email" class="form-control bg-transparent border-0" placeholder="Enter Email address"
                aria-label="Recipient's username" aria-describedby="button-addon2" />

              <button class="btn btn-primary" type="button" id="button-addon2">
                <i class="bx bxs-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
